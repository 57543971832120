.ui.inline.label {
  position: absolute;
  z-index: 100;
  top: 0.75em;
  right: 1em;
  margin: 0em 0em 0em 0em !important;
}
.ui.form .field > label {
  margin: 0em 0em 0em 0em;
}

.ui[class*="right inline"].input > input {
  padding-right: 5em !important;
}

.pull-right {
  float: right !important;
}

.ui.accordion .empty.title:not(.ui) {
  padding: 0em 0em;
}

.url.active {
  border-color: #3379c9;
  box-shadow: 0 0 5px#3379c9;
}

#urls .url {
  margin-bottom: 10px;
}
